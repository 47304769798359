@import '../../include_media';

.wrapper-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('../../images/tarot-bg.png');
    background-size:   auto;
    background-position: top center;
    &:before {
        content: '';
        width: 100%;
        height: 0;
        padding-top: 25%;
        background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }
    &:after {
        content: '';
        width: 100%;
        height: 0;
        padding-top: 25%;
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }
}
.game-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: scroll;
    transition: bottom 0.25s ease-in;

 
    .game-controls {
        pointer-events: none;
        position: fixed;
        bottom: 0;
        width: 200px;
        right: -50px;
        z-index: 10004;
        height: 75px;
       // background-color: #000;
        transition: opacity 0.25s ease-in;
        //box-shadow: 0 0 36px 11px #000;
        text-align: center;
        opacity: 0;
        button {
            font-family: 'Lexend Tera', sans-serif;
            color: #fff;
            border: 2px solid #333;
            width: 50px;
            height: 50px;
            border-radius: 100%;
            background-color: #000;
            font-size: 0;
            box-sizing: border-box;
            svg {
                path {
                    fill: #fff;
                }
            }
        }
    }
    &.show-controls {
        
        .game-controls {
            
            opacity: 1;
            pointer-events: all;
            button {
                cursor: pointer;
                
            }
        }
        
    }
    .game-status {
        position: fixed;
        right:-50px;
        bottom: 50px;
        width: 200px;
        z-index: 10003;
        //height: 100px;
        transition: bottom 0.25s ease-in;
        animation: fadeIn2 0.25s ease-in;
        pointer-events: none;
    }
    .game-message {
        border-left: 4px solid #220b1e;
        h2 {
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: 400;
            position: relative;
            z-index: 100004;
            button {
                background-color: transparent;

                padding: 0;
                border: 0;
                outline: 0;
                font-size: 0;
                vertical-align: middle;
                cursor: pointer;
                svg {
                    path {
                        transition: fill 0.25s ease-in;
                        fill: #555;
                    }
                }
                &:hover {
                    svg {
                        path {
                            fill: #ccc;
                        }
                    }
                }
            }
        }
        position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10002;
    box-sizing: border-box;
    
    padding: 1rem 200px 1rem 1rem;
    font-size: 0.9rem;
    color: #555;
    
    @include media('<1200px') {
        font-size: 0.75rem;
    }
    p {
        position: relative;
        z-index: 1000;
    }
    &:before {
        pointer-events: none;
        width: 100%;
        height: 100%;
        content: '';
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%,  rgba(0, 0, 0, 0) 100%);
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
    }
    }
}

.the-cards {
    position: relative;
    margin: 2rem 2rem;
   
    @include media('<1200px') {
        margin: 1rem 1rem;
    }
    .card-row {
       // margin: 0 0.5rem;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transition: transform 0.25s ease-in;
        z-index: 2;
    }

    .card-list {
        //gap: 1rem;
        perspective: 1000px;
       
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            transform-style: preserve-3d;
            text-align: center;
            li {
                padding: 0.5rem;
                display: inline-block;
                width: 20%;
                max-width: 150px;
                transform-style: preserve-3d;
                box-sizing: border-box;
                @include media('<1200px') {
                    width: 25%;
                    max-width: 250px;
                }
            }
        }
        //display: grid;
        //justify-content: center;
    //     grid-template-columns: repeat(auto-fill, 100px);
    // grid-auto-flow: column;
    // grid-gap: 20px;
        //grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    
}

@keyframes fadeIn2 {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }