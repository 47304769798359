.audio-control {
    position: absolute;
    z-index: 10002;
    top: 0;
    right: 0;
    button {
        padding: 0;
        margin: 0;
        background-color: transparent;
        border: 0;
        svg {
            opacity: 0.7;
            margin-top: 3px;
            margin-right: 0.5rem;
            path {
                fill: #666;
            }
        }
    }


    
}

.audio-settings-modal {
    .mute-button {
        margin-top: 1rem;
        background-color: #4e4631;
        border: 1px solid #746849;
        color: #FFF;
        font-family: 'Nanum Gothic Coding', monospace;
        text-transform: uppercase;
        padding: 0.5rem 1rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 2px;
        transition: border 0.25s, background-color 0.25s;
        box-shadow: 0 0 14px -2px #000;
        &:hover {
            border: 1px solid #94845c;
            background-color: #685d42;
        }
        &:disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
    label {
        display: block;
        text-align: left;
        margin: 1rem 0;
    }
    svg {
        path {
            fill: #666;
        }
    }
    .volume-control {
        display: grid;
        grid-template-columns: 24px 1fr;
        align-items: center;
        gap: 0.5rem;
        
    
        .volume-icon {
            font-size: 0;
        }
        input[type=range] {
            width: 100%;
        }
    }
}

