.turn-counter {
    position: relative;
    width: 200px;
    height: 150px;
}

.turn-stick {
    opacity: 1;
    animation: paddleAdd 0.3s ease-in;
    transition: transform 0.35s ease-in;
    pointer-events: none;
    height: 150px;
    position: absolute;
    left: 75px;
    top: 0;
    width: 50px;
    transform-origin: bottom;
    &:after {
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        content: '';
        width: 2px;
        height: 100px;
        background: rgb(255,255,255);
        background: linear-gradient(0deg, #33333300 33%, #333333 100%);
    }
    .paddle {
        box-sizing: border-box;
        border : 2px solid #333;
        width: 50px;
        height: 50px;
        text-align: center;
        position: relative;
        border-radius: 100%;
        background-color: rgba(0,0,0,0.75);
        font-weight: 600;
        span {
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            
        }
    }
}

@keyframes paddleAdd {
    0% { opacity: 0; transform: rotateZ(90deg) ; }
    100% { opacity:1; transform: rotateZ(0deg) ; }
  }