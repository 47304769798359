@import './include_media';

html , body {
  background-color: #0c0f14;
  height: 100%;
font-family: 'Lexend Tera', sans-serif;
color: #fff;

}
body {

}
.app {
  width: 100%;
  //overflow: hidden;
  //height: 100vh;
  header {
    &:after {
      content: '';
      position: absolute;
    top: 0;
      width: 100%;
      height: 50px;
      background: rgb(11,16,20);
background: linear-gradient(0deg, rgba(11,16,20,0) 0%, rgba(11,16,20,1) 100%);
    }
    
    overflow-y: visible;
    height: 0;
    width: 100%;
    padding-top:56%;
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    .overlay {
      box-shadow: 0 0 129px 69px #000;
      background: rgb(0,0,0);
background: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
      position: absolute;
      top:634px;
      left: 50%;
      text-transform: uppercase;
      text-align: center;
      display: inline-block;
      transform: translateX(-50%);
      border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    width: 50%;
    
    
      @include media('<1920px') {
        top: 60%;
      }
      @include media('<1600px') {
        
        width: 65%;
      }
      @include media('<1400px') {
        width: 85%;
      }
      @include media('<1200px') {
        width:95%;
        top: 90%;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        line-height: 3rem;
        font-size: 1rem;
      }
      @include media('<1000px') {
        top: 90%;
      }
      h1 {
        font-weight: 100;
        font-size: 2rem;
        letter-spacing: 1rem;
        @include media('<1200px') {
          font-size: 1.5rem;
        }
        @include media('<=tablet') {
          letter-spacing: 0.4rem;
          font-size: 1rem;
        }
      }
      h2 {
        font-weight: 100;
        position: absolute;
        width: 100%;
        font-size: 1rem;
        display:block;
        padding-top: 2rem;
        letter-spacing: 1rem;
        @include media('<1200px') {
          padding-top: 1rem;
          font-size: 0.8rem;
        }
        @include media('<tablet') {
          padding-top: 1rem;
          letter-spacing: 0.2rem;
          font-size: 0.7rem;
        }
      }
    }
    img {
      left:0;
      top: 0;
      width: 100%;
      position: absolute;

      @include media('<1200px') {
        left: 50%;
    top: 0;
    width: 160%;
    transform: translateX(-50%);
    position: absolute;
      }
      
    }
    
  }
}


.form-submit {
  transition: opacity 0.25s ease-in;
  &.loading {
    opacity: 0.5;
    pointer-events: none;
  }
  form {
    display: grid;
    grid-template-columns: 5fr 1fr;
    border-bottom: 1px solid #ccc;
    @include media('<1200px') {
      grid-template-columns: 2fr 1fr;
    }
    input, button {
      overflow: hidden;
      display: block;
      background-color: transparent;
      padding: 0.5rem;
      border:0;
      outline: none;
      color: #fff;
      font-family: 'Lexend Tera', sans-serif;
      font-size: 1rem;
      text-transform: uppercase;
      box-sizing: border-box;
      @include media('<1200px') {
        font-size: 0.85rem;
      }
    }
    button {
      cursor: pointer;
      color: #ccc;
      transition: color 0.5s ease-in-out;
      &:hover {
        color: #fff;
        
      }
    }
  }
  margin-bottom: 2rem;
}

.page-body {
  position: relative;
  
  padding: 2rem;
  width: 100%;
  margin: 0 auto;
  margin-top: -80px;
  box-sizing: border-box;
  max-width: 900px;
  @include media('<1200px') {
    margin-top: 100px;
  }
}

.guest-list {
  h2 {
    text-align: center;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 100;
    @include media('<1200px') {
      font-size: 1.2rem;
      
      }
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-size: 1rem;
    gap: 1rem;
    @include media('<1200px') {
      gap: 0.75rem;
      font-size: 0.9rem;
      grid-template-columns: 1fr 1fr;
      }
  }
  
  .guest-card {
    text-align: center;
    font-weight: 100;
    .name {
      display: block;
      text-transform: uppercase;
      -webkit-font-smoothing: antialiased;
    }
    .date {
      color: #acacac;
      display: block;
      font-size: 0.8rem;
      @include media('<1200px') {
        font-size: 0.7rem;
        
        }
      -webkit-font-smoothing: antialiased;
    }
  }
}

.fade-in-ani {
  animation: fadeinRow 0.3s ease-in;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
::-webkit-scrollbar-thumb:active {
  background: #000000;
}
::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}
::-webkit-scrollbar-corner {
  background: transparent;
}


@keyframes fadeinRow {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadeinRow {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeinRow {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadeinRow {
  from { opacity: 0; }
  to   { opacity: 1; }
}


.drive {
  text-align: center;
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
  padding: 1rem;
  margin: 0 auto;
  h2 {
    font-weight: 100;
    text-transform: uppercase;
  }
  input {

  }
  button {

  }
}