.card-row {
    &.show {
        visibility: visible;
    }
    &.hide {
        visibility : hidden;
    }
    &.fade-in {
        animation: fadeinRow 0.25s ease-in;
    }
}

@keyframes fadeinRow {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  
  /* Firefox < 16 */
  @-moz-keyframes fadeinRow {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  
  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadeinRow {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  
  /* Internet Explorer */
  @-ms-keyframes fadeinRow {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
