.deal-wrapper {
    overflow: visible;
    position: relative;
    height: 0;
    width: 100%;
    padding-top: 173%;
    opacity: 0;
    transform-origin: bottom;
    //opacity: 0.1;
    animation-name: fadeIn;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    //box-shadow: 1px 1px 10px 0px #000;
    //animation: fadeIn 0.5s ease-out forwards;
    &.card-0 {
        animation-delay: 0s;
    }
    &.card-1 {
        animation-delay: .25s;
    }
    &.card-2 {
        animation-delay: .50s;
    }
    &.card-3 {
        animation-delay: .75s;
    }
    &.card-4 {
        animation-delay: 1s;
    }
    &.card-5 {
        animation-delay: 1.25s;
    }
    &.card-6 {
        animation-delay: 1.5s;
    }
    &.card-7 {
        animation-delay: 1.75s;
    }
    &.card-8 {
        animation-delay: 2s;
    }
    &.card-9 {
        animation-delay: 2.25s;
    }
    &.card-10 {
        animation-delay: 2.5s;
    }
    &.card-11 {
        animation-delay: 2.75s;
    }
    &.card-12 {
        animation-delay: 3s;
    }
    &.card-13 {
        animation-delay: 3.25s;
    }
    &.card-14 {
        animation-delay: 3.5s;
    }
    &.card-15 {
        animation-delay: 3.75s;
    }
    &.card-16 {
        animation-delay: 4s;
    }
    img {
        width: 100%;
    }
    .card-back {
        position: absolute;
        left: 0;
        top: 0;
        backface-visibility: hidden;
        border-radius: 5px;
        overflow: hidden;
    }
}


.tarot-card {
    
        font-size: 0;
    -webkit-touch-callout:none;
-webkit-user-select:none;
-khtml-user-select:none;
-moz-user-select:none;
-ms-user-select:none;
user-select:none;
-webkit-tap-highlight-color:rgba(0,0,0,0);
    //box-shadow: 1px 1px 10px 0px #000;
    position: relative;
    height: 0;
    width: 100%;
    padding-top: 173%;
    //overflow: hidden;
    
    //animation: fadeIn 0.3s;
    //animation-fill-mode: forwards;
    transition: transform 1s;
    transform-style: preserve-3d;
    
    img {
        width: 100%;
        -webkit-user-drag: none; 
        -khtml-user-drag: none; 
        -moz-user-drag: none; 
        -o-user-drag: none; 
        user-drag: none; 
    }
    .card-back-wrapper {
        transform-style: preserve-3d;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
        height: 0;
    width: 100%;
    padding-top: 173%;
    border-radius: 5px;
    overflow: hidden;
    &:before {
        transition: box-shadow 0.25s ease-in;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        
        box-shadow: inset 0 0 0px -1px #fff;
        position: absolute;
        z-index: 101;
    }
    &:hover {
        &:before {
            box-shadow: inset 0 0 5px -1px #fff;
        }
    }
    
        // &:hover {
            
            
        //     //.card-back {
               
        //         transform: translate3d(0,0,10px) rotateY(25deg);
        //     //}
           
        // }
    }
    .card-back {
        
        transition: transform 0.25s ease-in-out;
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 0;
        backface-visibility: hidden;
        border-radius: 5px;
        overflow: hidden;
        
        z-index: 100;
    }
    .card-front {
        position: absolute;
        left: 0;
        top: 0;
        transform: rotateY( 180deg );
        backface-visibility: hidden;
        border-radius: 5px;
        overflow: hidden;
        pointer-events: none;
        z-index: 99;
    }
    &.revealed {
        transform: rotateY(180deg);
        pointer-events: none;
    }
    &.done {
        transform-origin: bottom;
        animation-name: fadeOut;
        animation-fill-mode: forwards;
        animation-duration: 0.25s;
        animation-timing-function: ease-out;
        .card-front {
            position: absolute;
            left: 0;
            top: 0;
            //transform: rotateY( 180deg );
            //backface-visibility: hidden;
            transform: none;
            backface-visibility: visible;
            border-radius: 5px;
            overflow: hidden;
        }
    }
}

@keyframes fadeIn {
    0% { opacity: 0; transform: rotate3d(1, 0, 0, -90deg) ; }
    100% { opacity: 1; transform: rotate3d(1, 0, 0, 0deg) ; }
  }

@keyframes fadeOut {
    0% { opacity: 1; transform: rotate3d(1, 0, 0, 0deg) ; }
    100% { opacity: 0; transform: rotate3d(1, 0, 0, 90deg) ; }
    
}